import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "index",
  //   component: () => import("../views/index.vue"),
  // },
  // {
  //   path: '/',
  //   name: 'payChoess',
  //   component: () => import('../views/payChoess.vue')
  // },
  // {
  //   path: '/oxPay',
  //   name: 'oxPay',
  //   component: () => import('../views/oxPay.vue')
  // },
  // {
  //   path: '/uuPay',
  //   name: 'uuPay',
  //   component: () => import('../views/uuPay.vue')
  // },
  // {
  //   path: '/vnCamelPay',
  //   name: 'vnCamelPay',
  //   component: () => import('../views/vnCamelPay.vue')
  // },
  // {
  //   path: '/wePay',
  //   name: 'wePay',
  //   component: () => import('../views/wePay.vue')
  // },
  // {
  //   path: '/',
  //   name: 'staticPay',
  //   component: () => import('../views/staticPay.vue')
  // },
  {
    path: '/',
    name: 'cardPay',
    component: () => import('../views/cardPay.vue')
  },
  // {
  //   path: '/demo',
  //   name: 'demo',
  //   component: () => import('../views/demo.vue')
  // },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
